.App__nav {
  height: var(--sizing_nav-height);
  background-color: var(--colors_white);

  display: flex;
  border-bottom: 1px solid var(--colors_light-grey);
  box-sizing: border-box;
}

.App__nav-links {
  display: flex;
  flex-grow: 1;
  justify-content: end;
  align-items: center;
  margin-right: 0.75rem;
}

.App__nav-link {
  padding: 1rem;
  display: flex;
  align-items: center;
  color: var(--colors_dark-green);
  text-decoration: none;
}
.App__nav-link-logo {
  height: var(--sizing_icon-height);
  width: var(--sizing_icon-height);
  margin-right: 0.25rem;
}
.App__nav-link:hover {
  color: var(--colors_green);
}
.App__nav-link:active {
  color: var(--colors_dark-green);
}

.App__nav-logo-container {
  height: 100%;
  padding: 1rem;
  margin-left: 0.75rem;
  box-sizing: border-box;
}
.App__nav-logo {
  height: 100%;
  object-fit: contain;
}

@media (max-width: 769px) {
  .App__nav-logo-container {
    margin-left: 0rem;
  }
  .App__nav-links {
    margin-right: 0rem;
  }
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: var(--colors_dark-green);
}
.wallet-adapter-button-trigger {
  background-color: var(--colors_green);
  margin-right: 1rem;
}

.ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
