.md-input__container {
  display: flex;
  flex-direction: column;
}

.md-input__label {
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.md-input__label--error {
  color: var(--colors_red);
}

.md-input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  font-size: 14px;
  line-height: 1.5715;
  background-color: var(--colors_white);
  background-image: none;
  border: 1px solid var(--colors_light-grey);
  border-radius: 2px;
  transition: all 0.3s;
}
.md-input:hover {
  border-color: var(--colors_light-blue);
  border-right-width: 1px;
}
.md-input:focus {
  border-color: var(--colors_light-blue);
  border-right-width: 1px;
  outline: 0;
}
.md-input--error {
  border-color: var(--colors_red);
}
