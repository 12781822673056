.mapboxgl-marker {
  width: var(--sizing_marker-height);
  height: var(--sizing_marker-height);
  z-index: 1;
  cursor: pointer;
}
.mapboxgl-marker.Map-Marker--nft {
  width: var(--sizing_marker-nft-height);
  height: var(--sizing_marker-nft-height);
  z-index: 1;
  cursor: pointer;
}

.Map-Marker--visible {
  z-index: 2;
}

.Map-Marker__icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.Marker-popover {
  background-color: white;
  color: black;
  /* display: flex; */
  padding: 1rem;
  border-radius: 3px;
  /* position: absolute; */
  /* bottom: var(--sizing_popover-offset); */
  z-index: 1000;
  display: none;
  transition: all 250ms;
  min-width: 250px;
}

.Marker-popover--visible {
  display: block;
}

.Marker-popover > h1 {
  font-size: var(--font-size_marker-title);
}

.Map-Marker__text {
  margin-bottom: 0.5rem;
}
.Map-Marker__date {
}

.Map-Marker__cluster {
  border-radius: 50%;
  color: var(--colors_white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
}

.Map-Marker__cluster--small {
  width: 40px;
  height: 40px;
  background-color: var(--colors_green);
}
.Map-Marker__cluster--medium {
  width: 50px;
  height: 50px;
  background-color: var(--colors_green);
}
.Map-Marker__cluster--large {
  width: 60px;
  height: 60px;
  background-color: var(--colors_dark-green);
}
