@font-face {
  font-family: 'Space Grotesk Bold Bold';
  src: url('https://d1w6lbg49kat6u.cloudfront.net/lINWXBz25YoxC2388VagLN1EbTHPQxjj/225848325007.woff')
    format('woff');
}

@font-face {
  font-family: 'Space Grotesk Light';
  src: url('https://d1w6lbg49kat6u.cloudfront.net/lINWXBz25YoxC2388VagLN1EbTHPQxjj/727795931178.woff')
    format('woff');
}

@font-face {
  font-family: 'Space Grotesk Medium';
  src: url('https://d1w6lbg49kat6u.cloudfront.net/lINWXBz25YoxC2388VagLN1EbTHPQxjj/16157407014.woff')
    format('woff');
}

@font-face {
  font-family: 'Space Grotesk Regular';
  src: url('https://d1w6lbg49kat6u.cloudfront.net/lINWXBz25YoxC2388VagLN1EbTHPQxjj/136610924011.woff')
    format('woff');
}

@font-face {
  font-family: 'Space Grotesk SemiBold';
  src: url('https://d1w6lbg49kat6u.cloudfront.net/lINWXBz25YoxC2388VagLN1EbTHPQxjj/849944900469.woff')
    format('woff');
}

html {
  font-family: 'Space Grotesk Regular';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --colors_dark-green: #184623;
  --colors_green: #4a8f5d;
  --colors_light-green: #86c994;
  --colors_yellow: #ffc919;
  --colors_ivory: #f3efcd;
  --colors_white: #ffffff;
  --colors_black: #000;
  --colors_light-grey: #d9d9d9;
  --colors_dark-blue: #0033a1;
  --colors_blue: #2f8dcc;
  --colors_light-blue: #90c6ea;
  --colors_red: #990000;
  --colors_light-red: #c70000;
  --colors_dark-red: #700000;

  --sizing_nav-height: 75px;
  --sizing_icon-height: 25px;
  --sizing_marker-height: 35px;
  --sizing_marker-nft-height: 50px;
  --sizing_popover-offset: 30px;
  --sizing_nft-size: 200px;

  --border-radius_card: 3px;
  --border-radius_button: 6px;

  --font-size_card-title: 18px;
  --font-size_marker-title: 14px;
  --font-weight_card-title: 600;
}
