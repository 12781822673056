.Profile__back-link {
  text-decoration: none;
  margin: 1rem;
}
.Profile__back {
  /* display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--colors_light-grey);
  border-radius: var(--border-radius_button);
  background-color: var(--colors_white);
  box-sizing: border-box; */
  /* padding: 0.75rem; */

  /* cursor: pointer; */
}
.Profile__back-icon {
  height: var(--sizing_icon-height);
  width: var(--sizing_icon-height);
  margin-right: 0.5rem;
}

.Profile__header {
  display: flex;
  justify-content: space-between;
}

.Profile__body-container {
  margin: 1rem;
  /* display: flex; */
}
.Profile__title {
  /* text-align: center; */
}

.Profile__wallet {
  margin-bottom: 2rem;
}

.Profile__section {
  margin-bottom: 2rem;
}

/* .Profile__location-container {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 1rem;
} */
.Profile__location {
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: auto auto auto;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;
  max-width: 200px;
}
.Profile__location-actions {
  display: flex;
  justify-content: end;
}
.Profile__location-info {
  margin: 1rem 0;
}
.Profile__monke-selection-text {
  margin: 1rem 0;
}
.Profile__monke-selection-text--error {
  color: var(--colors_light-red);
}

.Profile__location-switch {
  display: flex;
}
.Profile__hardware-switch {
  display: flex;
}
.Profile__location-switch-label {
  margin-left: 0.5rem;
  cursor: pointer;
}

.Profile__form {
}
.Profile__form > * {
  margin-bottom: 1rem;
  max-width: 20rem;
}

.Profile__location-coord-label {
  display: flex;
  align-items: center;
}

.Profile__actions {
  margin: 1rem;
  display: flex;
}
.Profile__actions > *:nth-child(n + 2) {
  margin-left: 1rem;
}

.Profile__delete {
  margin-top: 5rem;
}

.Profile__gallery {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, var(--sizing_nft-size));
}
.Profile__gallery-none {
  font-style: italic;
  color: var(--colors_light-blue);
}

.nft_gallery {
  height: var(--sizing_nft-size);
  width: var(--sizing_nft-size);
  cursor: pointer;
  opacity: 0.65;
  border: 1px solid var(--colors_white);
  box-sizing: border-box;
  transition: all 250ms ease;
  border-radius: 6px;
  overflow: hidden;
}
.nft_gallery--selected {
  border: 1px solid var(--colors_dark-green);
  opacity: 1;
  border-radius: 12px;
}
.nft_gallery:hover {
  border: 1px solid var(--colors_dark-green);
  opacity: 1;
}
.nft_gallery--disabled {
  cursor: default;
}

.nft_gallery_img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@media (max-width: 769px) {
  .Profile__location {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
    max-width: 100%;
  }
}
