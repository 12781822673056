.md-switch {
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    ),
    linear-gradient(to right, #fff, #fff);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.md-switch-checked {
  background: var(--colors_blue);
}
.md-switch-checked .md-switch-handle {
  left: calc(100% - 20px);
}
.md-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.md-switch-handle:before {
  position: absolute;
  inset: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px #00230b33;
  transition: all 0.2s ease-in-out;
  content: '';
}
