.Profile__view-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--sizing_nav-height));
}

.Profile__view-body-container {
  margin-top: 2rem;
}
.Profile__view-socials {
  max-width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Profile__view-location {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 1rem 1rem 1rem;
  color: var(--colors_dark-green);
  max-width: 75%;
}
.Profile__view-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Profile__view-text {
  display: flex;
  align-items: center;
  margin: 0.75rem;
  text-decoration: none;
  color: var(--colors_dark-green);
}
.Profile__view-text:hover {
  font-weight: 700;
}

.Profile__view-text-icon {
  height: var(--sizing_icon-height);
  width: var(--sizing_icon-height);
}
.Profile__view-text-value {
  margin-left: 0.5rem;
}

.Profile__view-nft {
  max-height: 300px;
  max-width: 300px;
  margin: 0 1rem;
  border-radius: 6px;
  overflow: hidden;
}
.Profile__view-name {
  text-align: center;
  color: var(--colors_dark-green);
  /* margin-bottom: 3rem; */
  word-wrap: break-word;
  width: 100%;
  margin: 1.5rem 1rem;
  font-weight: bold;
  font-size: 2em;
}
.Profile__view-card {
  max-width: 500px;
  margin: 0 auto;
  padding: 1.5rem;
  border: 1px solid;
  border-radius: 6px;

  color: var(--colors_dark-green);
}

@media (max-width: 769px) {
  .Profile__view-nft {
    max-height: 200px;
    max-width: 200px;
  }
  .Profile__view-name {
    font-size: 20px;
  }
  .Profile__view-location-icon {
    margin-bottom: 0.25rem;
    display: none;
  }
  .Profile__view-location {
    flex-direction: column;
  }
  .Profile__view-location-text {
    margin-left: 0;
    text-align: center;
  }
}
